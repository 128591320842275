body {
  box-sizing: border-box;
  min-height: 100vh;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #251a2a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiPagination-root {
  margin-top: 20px !important;
}
.Mui-selected {
  background-color: #2196f3 !important;
  color: #fff !important;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
