#root {
  height: 100%;
}

html {
  font-size: 16px;
}

main {
  padding-top: 80px;
}

@media screen and (min-width: 431px) {
  main {
    padding-right: 444px;
    padding-left: 99px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
