.markdown {
  background: #3c214d !important;
  color: white !important;
  font-family: 'Zen Dots';
}
.rc-md-editor {
  background: none;
  border: 2px solid rgba(176, 50, 214, 0.4);
  border-radius: 22px;
}
.rc-md-editor .rc-md-navigation {
  background: linear-gradient(90deg, #e15096 0%, #ae2cb1 53.12%, #4132cf 100%);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom: none;
}
.rc-md-editor .editor-container > .section {
  border-right: none;
}
.rc-md-editor .rc-md-navigation .button-wrap .button {
  color: white;
}

.editor-container {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.sec-md {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.rc-md-editor .editor-container .sec-md .input {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.preview {
  background: #3c214d !important;
  color: white !important;
  font-family: 'Zen Dots';
}

.rc-md-editor .editor-container .sec-html {
  background: #3c214d !important;
  color: white !important;
  font-family: 'Zen Dots';
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
