.reactMarkDown {
  font-family: 'Montserrat', sans-serif;
}

.reactMarkDown td {
  padding: 2px 5px;
  text-align: center;
  background: #f6f6f6;
}

.reactMarkDown table th {
  padding: 5px;
  background: #f6f6f6;
}

.reactMarkDown table {
  display: block;
  overflow: auto;
  border-spacing: 2px;
}

.reactMarkDown h1 {
  font-size: 36px;
  font-weight: 600;
  margin: 20px 0;
  color: white;
  font-family: 'Inter', sans-serif;
}

.reactMarkDown h2 {
  font-size: 32px;
  font-weight: 500;
  margin: 18px 0;
  color: white;
  font-family: 'Inter', sans-serif;
}

.reactMarkDown h3 {
  font-size: 28px;
  font-weight: 500;
  margin: 16px 0;
  color: white;
  font-family: 'Inter', sans-serif;
}

.reactMarkDown h4 {
  font-size: 24px;
  font-weight: 500;
  margin: 16px 0;
  color: white;
  font-family: 'Zen Dots', sans-serif;
}

.reactMarkDown blockquote p {
  padding: 25px;
  border-left: 10px solid #ebf6ff;
}

.reactMarkDown pre code {
  color: #717384;
}

.reactMarkDown h5 {
  font-size: 22px;
  font-weight: 500;
  margin: 16px 0;
  color: white;
  font-family: 'Zen Dots', sans-serif;
}

.reactMarkDown h6 {
  font-size: 18px;
  font-weight: 500;
  margin: 16px 0;
  color: white;
  font-family: 'Zen Dots', sans-serif;
}

.reactMarkDown p {
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
  font-family: 'Inter', sans-serif;
}

.reactMarkDown a {
  color: #2196f3;
}

.reactMarkDown ul {
  padding: 0;
}

.reactMarkDown ol {
  padding: 0;
}

.reactMarkDown ul li {
  font-size: 15px;
  font-weight: 400;
  color: white;
  font-family: 'Inter', sans-serif;
}

.reactMarkDown ol li {
  font-size: 15px;
  font-weight: 400;
  color: white;
  font-family: 'Inter', sans-serif;
}
.reactMarkDown li {
  line-height: 160%;
  margin-left: 25px;
  font-family: 'Inter', sans-serif;
}

.reactMarkDown a {
  text-decoration: none;
  color: white;
  font-family: 'Zen Dots', sans-serif;
}
